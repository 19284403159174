@import "styles/fonts";
@import "styles/master";

.app {
  color: var(--fontColor_default);
  background-color: var(--backGroundColor_default);
  font-family: "Montserrat", sans-serif;
  font-weight: var(--fontWeight_normal);
  font-size: var(--fontSize_small);
  display: grid;
  grid-template-areas: "sidebar body";
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.app-sidebar {
  grid-area: sidebar;
}

.app-body {
  grid-area: body;
  position: relative;
  &.panel-overlay {
    padding-left: 64px;
  }
  overflow: auto;
}

.spring-page {
  width: 100%;
  height: 100%;
}

.flex-container {
  display: flex;
  background: rgb(239, 239, 239);
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
