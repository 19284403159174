/*Bootstrap buttons*/
// TODO: rename to more generic className if used in different context rather than header
.btn {
  &.btn-header-action {
    background-color: var(--color_primary);
    border-radius: 16px;
    color: var(--color_white_1);
    height: 32px;
    width: 32px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    &:hover {
      background-color: var(--color_primary);
      color: var(--color_white_1);
    }
  }
  &.btn-calendar-interval {
    background-color: var(--color_white_1);
    border-radius: 4px;
    color: var(--color_primary);
    height: 26px;
    font-size: 14px;
    padding: 0px 11px;
    font-weight: 300;
    border: 1px solid var(--color_gray_5);
    margin: 2px;
    &:hover {
      background-color: var(--color_white_1);
      color: var(--color_primary);
    }
    // TODO: update when having styles for it
    &--checked {
      background-color: var(--color_gray_4);
      color: var(--color_primary);
      &:hover {
        background-color: var(--color_gray_4);
        color: var(--color_primary);
      }
    }
  }
}

/*Material-ui buttons*/
.MuiButtonBase-root {
  &.icon-button-row-action {
    padding: 0;
    margin-left: 32px;
    color: var(--color_primary);
    fill: transparent;
    stroke: var(--color_primary);
    &:focus,
    &:hover {
      background: transparent;
      outline: none;
      fill: transparent;
      stroke: var(--color_primary);
    }
  }
}
