@import "colors";
@import "buttons";
@import "spinner";

// default font sizes
:root {
  --fontSize_base: 16px;
  --fontSize_h1: 34.707px;
  --fontSize_h2: 30.176px;
  --fontSize_h3: 26.243px;
  --fontSize_h4: 22.816px;
  --fontSize_h5: 19.8375px;
  --fontSize_h6: 17.25px;
  --fontSize_p: 15px;
  --fontSize_small: 14px;
  --fontSize_x_small: 12px;

  // Font opacity
  --font_opacity: 0.6;
  --font_opacity_hightlighted: 0.6;

  // font weight
  --fontWeight_normal: 400;
  --fontWeight_medium: 500;
  --fontWeight_semibold: 600;
  --fontWeight_bold: 700;

  //general line-height
  --lineHeight_normal: 18px;
  --lineHeight_small: 16px;

  --backGroundColor_default: var(--color_white_1);

  //general elements font color
  --fontColor_default: var(--color_gray_1);
  --fontColor_gridHeader: var(--color_gray_3);

  --fontColor_navbar: var(--color_primary_1);
  --fontColor_navbar_highlighted: var(--color_white_1);
  --fontColor_h1: var(--color_gray);

  --backGroundColor_icon_button: var(--color_black_3);

  // sidebar
  --width_sidebar: 63px;
  --width_sidebar_open: 250px;
  --fontSize_sidebar_text: 15px;
  --fontSize_sidebar_icon: 25px;
  --fontSize_sidebar_toggle_icon: 18px;
  --fontSize_sidebar_spring_icon: 35px;
  --fontColor_navbar_active: var(--color_white_1);

  // header
  --height_header: 73px;
}
