@font-face {
  font-family: "Montserrat";
  src: url("./../assets/fonts/montserrat/montserrat-bold-webfont.woff2")
      format("woff2"),
    url("./../assets/fonts/montserrat/montserrat-bold-webfont.woff")
      format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./../assets/fonts/montserrat/montserrat-medium-webfont.woff2")
      format("woff2"),
    url("./../assets/fonts/montserrat/montserrat-medium-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./../assets/fonts/montserrat/montserrat-semibold-webfont.woff2")
      format("woff2"),
    url("./../assets/fonts/montserrat/montserrat-semibold-webfont.woff")
      format("woff");
  font-weight: 600;
  font-style: normal;
}


@font-face {
  font-family: "Barlow";
  src: local('Rajdhani'), url("../assets/fonts/barlow/barlow-medium.ttf") format('truetype')
}