@import "styles/fonts";
@import "styles/master";

* {
	margin: 0;
}

body {
	margin: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

h2,
h3,
h4,
h5,
h6 {
	font-weight: var(--fontWeight_normal);

	&.bold {
		font-weight: var(--fontWeight_semibold);
	}
}

h1 {
	font-weight: --fontWeight_bold !important;
	font-size: --fontSize_h1 !important;
	line-height: 42px !important;
	color: var(--fontColor_h1);
}

h2 {
	font-size: var(--fontSize_h2);
}

h3 {
	font-size: var(--fontSize_h3);
}

h4 {
	font-size: var(--fontSize_h4);
}

h5 {
	font-size: var(--fontSize_h5);
}

h6 {
	font-size: var(--fontSize_h6);
}

p {
	font-size: var(--fontSize_p);
}

input {
	color: var(--fontColor_default);
}

ul {
	padding-inline-start: 0;
}
