@import "./../../styles/master";

.sidebar {
  background-color: var(--color_primary);
  width: 64px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  top: 0;
  transition: all 100ms ease-in;
  z-index: 2;

  .sidebar-spring-logo {
    align-items: center;
    display: flex;
    height: 80px;
    justify-content: center;
    margin: 0 auto;
    transition: none;
  }

  .sidebar-items {
    // overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .sidebar-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0;
    list-style: none;
    height: 60px;
    margin-left: 10px;

    a {
      color: var(--fontColor_navbar);
      fill: var(--fontColor_navbar);
      stroke: var(--fontColor_navbar);
      font-size: 18px;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 10px;
      border-radius: 4px;
      width: 90%;

      &.active,
      &:hover {
        color: var(--fontColor_navbar_highlighted);
        fill: var(--fontColor_navbar_highlighted);
        stroke: var(--fontColor_navbar_highlighted);
        text-decoration: none;
      }
    }
  }

  .last-item-bottom {
    margin-top: auto;
  }

  .sidebar-item-text {
    margin-left: 16px;
  }

  &.expanded {
    width: 220px;

    .sidebar-spring-logo {
      justify-content: left;
      margin: 0 22px;
      width: 128px;
    }
  }

  &.overlay {
    position: fixed;
  }
}
