$colors: (
  black: #262626,
  primary: #00aeef,
  primary_1: #99dff9,
  secondary: #00a79d,
  danger: #ef4c00,
  success: #2ecc71,
  warning: #f2c94c,
  gray: #414042,
  gray_1: #828282,
  gray_2: #A1A1A1,
  gray_3: #CDCDCD,
  gray_4: #E1E1E1,
  gray_5: #F1F3F4,
  gray_6: #737373,
  gray_7: #8C8C8C,
  white_1: #ffffff,
  edit-background: #d7d7d7,
);

:root {
  @each $color, $value in $colors {
    --color_#{$color}: #{$value};
  }
}
